@import '_config.scss';
@import '_utility.scss';

body {
    min-height: 100vh;
    position: relative;
    background-image: url(../images/background.jpg);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $main-font;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -5;
        background-color: rgba(0, 0, 0, .4);
    }
}

@import '_mobile.scss';