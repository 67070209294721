@import '_config.scss';

.projects {
  $self: &;
  padding-top: 60px;

  &__title {
    font-size: 1.8em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    transform: translateX(-150%);
    animation: slide 1.5s forwards;
  }

  &__projects-container {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 50px;

    .projects__project {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      grid-gap: 15px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      align-items: center;
      transform: translateX(-150%);
      animation: slide 1.5s forwards;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }

      &-links {
        height: 80%;
        margin-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }

      &-link {
        padding: 5px 10px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 7px;
        transition: 0.3s;

        &:hover {
          transform: scale(1.2);
          font-weight: bold;
        }
      }

      &-image {
        width: 300px;
        height: 200px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
      }

      &-info {
        margin-right: 10px;
        margin-left: 10px;
        text-align: center;
      }

      &-info-title {
        display: block;
        margin-bottom: 10px;
        letter-spacing: 1px;
      }

      &-info-subtitle {
        margin-bottom: 8px;
      }

      &-info-tech {
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(-150%);
    }

    100% {
      transform: translateX(0);
    }
  }
}
