@import '_config.scss';

@include mediaSmall {
  .navigation__logo--move {
    transform: translateY(105%);
    z-index: 1;
  }

  .navigation {
    &__logo--move {
      transform: translateY(105%);
      z-index: 1;
    }

    &__item {
      $self: &;
      &:hover i {
        color: inherit;
      }

      &:hover .navigation__item-title {
        opacity: 0;
      }
    }

    &-link {
      &:hover {
        transform: none;
        font-weight: normal;
      }
    }
  }

  .main {
    $self: &;
    h2 {
      font-size: 1.5rem;
    }

    .home__welcome-text {
      font-size: 1.4rem;
    }

    .about {
      margin-top: 50px;

      &__experience-item {
        font-size: 1rem;
        text-align: justify;
      }

      .about__experience-item-title {
        margin-top: 20px;
      }

      &__skills-container {
        .about__skills-title {
          margin-top: 30px;
        }

        .about__skills-list {
          margin-top: 20px;
          margin-bottom: 20px;

          .about__skills-item {
            .about__skills-item-logo {
              height: 35px;
            }
          }
        }
      }
    }

    .projects {
      $self: &;
      &__projects-container {
        margin-top: 40px;
        margin-bottom: 20px;

        .projects__project {
          margin: 0 auto;
          padding-bottom: 20px;
          width: 90%;
          max-width: 330px;
          display: block;
          text-align: center;

          &-links {
            height: 25px;
            margin-top: 20px;
            margin-bottom: 20px;
            flex-direction: row;
          }

          &-image {
            width: 100%;
            height: 220px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          }

          &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }

    .contact {
      margin-top: 20px;

      &__form {
        width: 100%;
      }
    }
  }
}

@include mediaSmallFooter {
  .main {
    .contact {
      padding-top: 40px;

      &__info {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span,
        div {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@include mediaSmallProjects {
  .main {
    $self: &;
    .projects {
      $self: &;
      &__projects-container {
        margin-top: 40px;
        margin-bottom: 20px;

        .projects__project {
          margin: 0 auto;
          padding-bottom: 20px;
          width: 90%;
          max-width: 400px;
          display: block;
          text-align: center;

          &-links {
            height: 25px;
            margin-top: 20px;
            margin-bottom: 20px;
            flex-direction: row;
          }

          &-image {
            width: 100%;
            height: 250px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          }

          &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }
  }
}
@include mediaMedium {
  .navigation {
    &__items-list-container {
      transform: translateY(0);
    }

    &__items-list {
      max-width: 500px;
    }

    &__menu-btn {
      display: none;
    }
  }

  .about {
    &__skills-container {
      .about__skills-list {
        .about__skills-item-logo {
          height: 50px;
        }
      }
    }
  }
}

@include mediaBig {
  .home {
    margin-left: 40px;
  }

  .about {
    &__skills-container {
      .about__skills-list {
        .about__skills-item-logo {
          height: 70px;
        }
      }
    }
  }
}

@include mediaLarge {
  body::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .main {
    margin-left: 100px;
  }

  .about {
    &__skills-container {
      .about__skills-list {
        .about__skills-item-logo {
          height: 75px;
        }
      }
    }
  }

  .home__welcome-text {
    font-size: 3rem;
  }

  .navigation {
    &__items-list-container {
      width: 85px;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0 auto;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__items-list {
      height: 60%;
      flex-direction: column;
    }
  }
}

@include mediaSmallLandscape {
  .main {
    margin-left: 25px;
    min-height: calc(100vh - #{$foot-height} + 2px);
  }

  .navigation {
    &__logo {
      margin-top: 5px;
      margin-left: 5px;
      position: fixed;
      z-index: 100;
      font-size: 1.5rem;
    }

    &__items-list-container {
      width: 60px;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0 auto;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__items-list {
      height: 80%;
      flex-direction: column;
    }

    &__item {
      $self: &;

      & i {
        font-size: 1.4rem;
      }
      &:hover i {
        color: inherit;
      }

      &:hover .navigation__item-title {
        opacity: 0;
      }
    }

    &-link {
      &:hover {
        transform: none;
        font-weight: normal;
      }
    }
  }

  .about {
    $self: &;
    padding-top: 20px;

    &__experience-title {
      margin-bottom: 20px;
    }

    &__experience-container {
      transform: translateX(-150%);
      animation: slide 1.2s forwards;
    }

    &__experience-item-title {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    &__skills-container {
      .about__skills-title {
        margin-top: 40px;
      }

      .about__skills-list {
        .about__skills-item-logo {
          height: 50px;
        }
      }
    }
  }

  .projects {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .contact {
    $self: &;
    padding-top: 25px;

    &__info {
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
}
