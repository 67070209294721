@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

$nav-height: 50px;
$foot-height: 40px;
$primary-color: #04c6fe;
$main-font: "Roboto", sans-serif;

@mixin mediaSmall {
  @media screen and (max-width: 550px) {
    @content;
  }
}

@mixin mediaSmallFooter {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaSmallProjects {
   @media screen and (min-width: 551px) and (max-width: 675px) {
    @content;
  }
}

@mixin mediaMedium {
  @media screen and (min-width: 551px) {
    @content;
  }
}

@mixin mediaBig {
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mediaLarge {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin mediaSmallLandscape {
  @media screen and (max-height: 600px) {
    @content;
  }
}
