@import '_config.scss';

.contact {
    $self: &;
    padding-top: 60px;
    opacity: 0;
    animation: showContact 1s forwards;

    &__title {
        font-size: 1.8em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    &__form {
        margin: 30px auto;
        height: 400px;
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &-section {
            margin-top: 10px;
            width: 100%;
            font-size: 1.3em;

            &-label {
                display: block;
                margin-bottom: 5px;
            }

            &-input,
            &-text {
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                font-size: .7em;
            }

            &-text {
                height: 100px;
                resize: vertical;
                font-family: $main-font;
            }

            &-error {
                display: block;
                height: 20px;
                color: red;
                font-size: .7em;
                opacity: 1;
            }
        }

        &-submit-btn {
            margin-top: 20px;
            width: 150px;
            background-color: $primary-color;
            font-size: 1rem;
        }
    }

    &__info {
        margin-top: 180px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #ddd;

        &-icon {
            margin-right: 5px;
            font-size: 1.5rem;
            vertical-align: text-bottom;
        }

        &-social-link {
            margin-left: 10px;
            font-size: 1.5rem;
        }
    }
}

@keyframes showContact {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}