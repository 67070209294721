@import '_config.scss';

.home {
    $self: &;
    display: flex;
    align-items: center;

    &__welcome-text {
        font-size: 2.5rem;
        letter-spacing: 2px;
    }

    &__title-text {
        display: block;
        transform: translateX(-150%);
        animation: slide 1s forwards;
        animation-delay: .5s;
    }

    &__title-text-end {
        display: block;
        color: $primary-color;
        transform: translateX(-150%);
        animation: slide 1s forwards;
        animation-delay: 1.5s;
    }
}