* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:focus,
input:focus,
textarea:focus {
    outline: 0;
}

li {
    list-style: none;
}

a {
    color: white;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-tap-highlight-color: transparent;
}

.mark {
    color: $primary-color;
}