@import '_config.scss';

.about {
  $self: &;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.2em;

  &__experience-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    transform: translateX(-150%);
    animation: slide 1.2s forwards;
  }

  &__experience-container {
    transform: translateX(-150%);
    animation: slide 1.2s forwards;

    &__experience-title {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      transform: translateX(-150%);
    }
  }

  &__skills-container {
    overflow: hidden;
    .about__skills-title {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      opacity: 0;
      animation: appear 1.5s forwards;
      animation-delay: 0.5s;
    }

    .about__skills-list {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .about__skills-item {
        opacity: 0;
        animation: appear 1.5s forwards;
        animation-delay: 0.5s;

        &:nth-of-type(1) {
          animation-delay: 0.4s;
        }

        &:nth-of-type(2) {
          animation-delay: 0.5s;
        }

        &:nth-of-type(3) {
          animation-delay: 0.6s;
        }

        &:nth-of-type(4) {
          animation-delay: 0.7s;
        }

        &:nth-of-type(5) {
          animation-delay: 0.8s;
        }

        &:nth-of-type(6) {
          animation-delay: 0.9s;
        }
      }

      .about__skills-item-logo {
        height: 75px;
      }
    }

    .about__skills-minor-list-container {
      margin-top: 30px;
      margin-bottom: 10px;
      position: relative;
      text-align: center;
      .about__skills-minor-list {
        width: 1200px;
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        transform: translateX(-1200px);
        animation: skillsSlide 15s linear infinite;
        animation-delay: 1s;
        .about__skills-minor-item {
          font-size: 1rem;
        }
      }

      .about__skills-minor-list--second {
        width: 1200px;
        display: flex;
        justify-content: space-evenly;
        transform: translateX(-1200px);
        animation: skillsSlide 15s linear infinite;
        animation-delay: 8.5s;

        .about__skills-minor-item {
          font-size: 1rem;
        }
      }
    }
  }
}

@keyframes skillsSlide {
  0% {
    transform: translateX(-1200px);
  }

  100% {
    transform: translateX(1200px);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
